import Image from 'next/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { Stack } from '@chakra-ui/react'
import { IconButton, Link } from '@opengovsg/design-system-react'
import { BiLogOutCircle } from 'react-icons/bi'

import {
  CONSUMPTION_ROUTES,
  CORPORATIONS_ROUTES,
  FACILITIES_ROOT,
  MEMBER_ROUTES,
  OVERVIEW_ROUTES,
  PASSES_ROUTES,
  PAYMENT_LINK_ROUTES,
  PRINCIPAL_ROUTES,
  PROGRAMMES_ROUTES,
} from '~/constants/routes'
import { useAuthzContext } from '~/contexts'
import { useMe } from '~/features/me/api'

import { NavigationTab, NavigationTabList } from './NavigationalTabs'

export const AppNavbar = (): JSX.Element => {
  const { pathname } = useRouter()
  const { logout, me } = useMe()

  const { ability } = useAuthzContext()

  return (
    <Stack
      bg="base.canvas.default"
      flexDir="row"
      px="1.5rem"
      py="1rem"
      shadow="navbar"
    >
      <Stack
        css={{
          '&::-webkit-scrollbar': {
            width: '0',
            height: '0',
          },
        }}
        flex={1}
        flexDir="row"
        gap="2rem"
        h="fit-content"
        overflow="scroll"
        overflowX="hidden"
      >
        <Link as={NextLink} href="/" minW={90}>
          <Image
            priority
            alt="ActiveSG Logo"
            height={32}
            src="/assets/activesg-logo-colour.png"
            width={90}
          />
        </Link>
        <NavigationTabList>
          {ability.can('read', 'Booking') && (
            <NavigationTab
              href={OVERVIEW_ROUTES.root()}
              isActive={pathname.startsWith(OVERVIEW_ROUTES.root())}
            >
              Overview
            </NavigationTab>
          )}
          {ability.can('read', 'Membership') && (
            <NavigationTab
              href={MEMBER_ROUTES.individuals().root()}
              isActive={pathname.startsWith(MEMBER_ROUTES.root())}
            >
              Members
            </NavigationTab>
          )}
          {ability.can('read', 'Corporation') && (
            <NavigationTab
              href={CORPORATIONS_ROUTES.root()}
              isActive={pathname.startsWith(CORPORATIONS_ROUTES.root())}
            >
              Corporations
            </NavigationTab>
          )}
          {ability.can('read', 'FacilityClosure') && (
            <NavigationTab
              href={`${FACILITIES_ROOT}/activities`}
              isActive={pathname.startsWith('/facilities')}
            >
              Facilities
            </NavigationTab>
          )}
          {ability.can('read', 'Programme') && (
            <NavigationTab
              href={PROGRAMMES_ROUTES.root()}
              isActive={pathname.startsWith(PROGRAMMES_ROUTES.root())}
            >
              Programmes
            </NavigationTab>
          )}
          {ability.can('read', 'Pass') && (
            <NavigationTab
              href={PASSES_ROUTES.root()}
              isActive={pathname.startsWith(PASSES_ROUTES.root())}
            >
              Passes
            </NavigationTab>
          )}
          {ability.can('create', 'CheckoutSession') && (
            <NavigationTab
              href={PAYMENT_LINK_ROUTES.root()}
              isActive={pathname.startsWith(PAYMENT_LINK_ROUTES.root())}
            >
              Payment Link
            </NavigationTab>
          )}
          {ability.can('update', 'ConsumePass') && (
            <NavigationTab
              href={CONSUMPTION_ROUTES.root()}
              isActive={pathname.startsWith(CONSUMPTION_ROUTES.root())}
            >
              Scan Pass
            </NavigationTab>
          )}
          {ability.can('create', {
            __typename: 'Principal',
            organisationId: me.organisation.id,
          }) && (
            <NavigationTab
              href={PRINCIPAL_ROUTES.root()}
              isActive={pathname.startsWith(PRINCIPAL_ROUTES.root())}
            >
              Principals
            </NavigationTab>
          )}
        </NavigationTabList>
      </Stack>
      <IconButton
        aria-label="Logout"
        icon={<BiLogOutCircle />}
        variant="clear"
        onClick={() => logout()}
      />
    </Stack>
  )
}

import { useLayoutEffect } from 'react'

import { useRouter } from 'next/router'

import { Skeleton } from '@chakra-ui/react'

import { type NextPageWithLayout } from '@activesg/ui/types'

import { OVERVIEW_ROUTES, PASSES_ROUTES } from '~/constants/routes'
import { useAuthzContext } from '~/contexts'
import { AuthedLayout } from '~/templates/layouts/AuthedLayout'

// Used for redirecting according to a user's role
export const IndexPage: NextPageWithLayout = () => {
  const { ability } = useAuthzContext()
  const router = useRouter()

  // FIXME: When we have more roles in the future, redirect to other routes
  useLayoutEffect(() => {
    if (ability.can('read', 'Booking')) {
      void router.replace(OVERVIEW_ROUTES.root())
    } else {
      void router.replace(PASSES_ROUTES.root())
    }
  })

  return <Skeleton h="$100vh" w="$100vw" />
}

IndexPage.getLayout = AuthedLayout

export default IndexPage
